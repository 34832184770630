.nk-entry {
    color: $base-color;
    font-weight: 400;
    img {
        border-radius: $border-radius;
    }
    h1,
    h2,
    h3 {
        + p {
            margin-top: 1rem;
        }
        + img {
            margin-top: 0.75rem;
        }
    }
    h4,
    h5,
    h6 {
        + p {
            margin-top: 0.75rem;
        }
    }
    img:not(:last-child) {
        margin-bottom: 2rem;
    }
    p {
        + h1,
        + h2,
        + h3,
        + h4,
        + h5,
        + h6 {
            margin-top: 2rem;
        }
    }
    ul + h1,
    ul + h2,
    ul + h3,
    ul + h4,
    ul + h5,
    ul + h6,
    ul + p,
    ol + h1,
    ol + h2,
    ol + h3,
    ol + h4,
    ol + h5,
    ol + h6,
    ol + p {
        margin-top: 1.25rem;
    }
    ul + img,
    ol + img {
        margin-top: 2rem;
    }
    pre + h1,
    pre + h2,
    pre + h3,
    pre + p {
        margin-top: 1.75rem;
    }
    blockquote {
        background-color: #faf9ff;
        padding: 1rem;
    }
    ul,
    ol {
        list-style: inherit;
        padding-left: 1rem;
    }
    ol {
        list-style-type: decimal;
    }
    ul li,
    ol li {
        line-height: 1.8;
    }
    p + img {
        margin-top: 1.25rem;
    }
}
