.text {
    &-base {
        color: $text-base !important;
    }
    &-light {
        color: $text-light !important;
    }
    &-layout {
        &-secondary {
            color: #6472b9 !important;
        }
    }
}

.smaller {
    font-size: $smaller-font-size;
}

.lead-text {
    font-weight: $lead-text-font-weight;
    color: $lead-text-color;
    display: block;
}
.sub-text {
    font-size: 0.75rem;
    color: $sub-text-color;
    display: block;
    font-weight: $sub-text-font-weight;
}

.is-theme {
    .sub-text,
    .lead-text {
        color: $white;
    }
    .sub-text {
        font-weight: 400;
    }
}
