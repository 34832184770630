.social-links {
    gap: 2.5rem;
    display: flex;
    align-items: center;
    .icon {
        font-size: 26px;
    }
    a {
        color: $base-color;
        &:hover {
            color: $primary;
        }
    }

    &.active-color {
        a {
            color: $primary;
        }
    }
}
