// btn
.btn {
    align-items: center;
    line-height: 1.5rem;
    display: inline-flex;
    justify-content: center;
    text-transform: capitalize;

    // Button Size
    &-lg {
        @include media-breakpoint-up(lg) {
            padding: 0.75rem 2.25rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 0.75rem 3.5rem;
        }
    }

    &-primary {
        &:hover {
            background-color: mix($primary, $white, 90%) !important;
        }
    }

    &-primary-alt {
        &:hover {
            background-color: mix($primary-alt, $white, 90%) !important;
        }
    }

    &-purple {
        &:hover {
            background-color: mix($purple, $white, 90%) !important;
        }
    }

    &-warning {
        &:hover {
            background-color: mix($yellow, $white, 90%) !important;
        }
    }

    &-danger {
        &:hover {
            background-color: mix($red, $white, 90%) !important;
        }
    }

    &-success {
        &:hover {
            background-color: mix($success, $white, 90%) !important;
        }
    }

    &-success-alt {
        &:hover {
            background-color: mix($success-alt, $white, 90%) !important;
        }
    }

    &-info {
        &:hover {
            background-color: mix($info, $white, 90%) !important;
        }
    }

    &-info-alt {
        &:hover {
            background-color: mix($info-alt, $white, 90%) !important;
        }
    }

    &-indigo {
        &:hover {
            background-color: mix($indigo, $white, 90%) !important;
        }
    }

    &-success,
    &-outline-success,
    &-success-alt,
    &-outline-success-alt,
    &-outline-primary-alt,
    &-outline-danger,
    &-outline-indigo {
        &:hover {
            color: #fff;
        }
    }

    // reset border color
    &:hover,
    &:active {
        border-color: transparent;
    }

    &:hover:not([class*="btn-"]) {
        color: $input-btn-hover-color;
    }
    .icon {
        font-size: 1.125rem;
    }
    .icon + span,
    span + .icon {
        margin-left: 0.625rem;
    }

    &-outline {
        &-warning {
            &:hover {
                color: $white;
            }
        }
    }
    &-light,
    &-outline-light {
        color: $base-color;
    }

    &-link {
        color: $darker;
        font-size: 1rem;
        align-items: center;
        display: inline-flex;
        font-weight: $fw-semibold;
        .icon + span,
        span + .icon {
            margin-left: 0.5rem;
        }
    }
    &-icon {
        width: 2.5rem;
        height: 2.5rem;
        --#{$prefix}btn-padding-y: 0;
        --#{$prefix}btn-padding-x: 0;

        &.btn {
            &-xs {
                width: $size-xs;
                height: $size-xs;
            }
            &-sm {
                width: $size-sm;
                height: $size-sm;
            }
            &-md {
                width: $size-md;
                height: $size-md;
            }
        }
    }
    &-block {
        display: flex;
        width: 100%;
    }

    &-white {
        color: $darker;
    }
}

//btn zoom
@mixin btn-zoom-active {
    opacity: 1;
    height: 120%;
    width: 120%;
}

.btn-zoom {
    position: relative;
    z-index: 1;
    color: $base-text;
    border-color: transparent !important;
    &:focus {
        box-shadow: none;
    }
    &:before {
        position: absolute;
        z-index: -1;
        width: $btn-zoom-width;
        height: $btn-zoom-height;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: 50% 50%;
        content: "";
        background-color: $btn-zoom-bg;
        border-radius: 50%;
        opacity: 0;
        transition: all 0.3s ease;
        .show > & {
            @include btn-zoom-active();
        }
    }
    &:hover:before,
    &:focus:before,
    &.active:not(.revarse):before {
        @include btn-zoom-active();
    }
    &.active:hover:before {
        background-color: $btn-zoom-bg;
    }
    a:hover & {
        &:before {
            @include btn-zoom-active();
        }
    }
}

// responsive breakpoints
@include media-breakpoint-up(lg) {
    .btn {
        &-icon {
            width: $size-rg;
            height: $size-rg;
        }
    }
}
