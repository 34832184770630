.nk-sticky-badge { 
   right: 0px;
   top: 50%;
   z-index: 999;
   position: fixed;
   overflow: hidden;
   transform: translateY(-50%);
   border-top-left-radius: 4px;
   border-bottom-left-radius: 4px;
   &-icon { 
    color: #fff;
    font-size: 18px;
    padding: 8px 12px;
    overflow: hidden;
    transform: scale(1);
    display: block;
    transition: all 0.2s linear;

    &:hover { 
      color: #fff;
    }
    &:active { 
      transform: scale(0.9);
    }
   }

   &-home { 
    background-color: #2B5CFD;
   }

   &-purchase { 
    background-color: #07ba63;
   }
}