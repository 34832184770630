.nk-miscell {
  &-include {
    ul {
      gap: 12px;
      display: grid;
      grid-template-columns: 1fr;
      @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-up(lg) {
        max-width: 440px;
      }

      li {
        a {
          em {
            color: $primary;
          }

          span {
            color: $darker;
            position: relative;
            display: inline-block;

            &::after {
              left: 0px;
              content: "";
              bottom: -2px;
              width: 100%;
              height: 1px;
              position: absolute;

              background-color: $darker;
            }
          }
        }
      }
    }
  }
}
