.swiper {
    &-wrapper {
        &.has-pagination {
            padding-bottom: 60px;
            &-and-navigation {
                padding-bottom: 100px;
                + .swiper-pagination-fraction,
                + .swiper-pagination-custom,
                + .swiper-horizontal > .swiper-pagination-bullets,
                + .swiper-pagination-bullets.swiper-pagination-horizontal {
                    bottom: 100px;
                }
            }
        }
        &.has-navigation {
            padding-bottom: 40px;
        }
    }

    &-button {
        &-group {
            &.text-primary-alt {
                .swiper-button-prev,
                .swiper-button-next {
                    &:hover {
                        background-color: $primary-alt;
                    }
                }
            }

            &.text-green,
            &.text-success {
                .swiper-button-prev,
                .swiper-button-next {
                    &:hover {
                        background-color: $green !important;
                    }
                }
            }

            &.text-green-alt,
            &.text-success-alt {
                .swiper-button-prev,
                .swiper-button-next {
                    &:hover {
                        background-color: $green-alt !important;
                    }
                }
            }

            &.text-purple {
                .swiper-button-prev,
                .swiper-button-next {
                    &:hover {
                        background-color: $purple !important;
                    }
                }
            }

            &.text-info {
                .swiper-button-prev,
                .swiper-button-next {
                    &:hover {
                        background-color: $info !important;
                    }
                }
            }

            &.text-info-alt {
                .swiper-button-prev,
                .swiper-button-next {
                    &:hover {
                        background-color: $info-alt !important;
                    }
                }
            }

            &.text-danger {
                .swiper-button-prev,
                .swiper-button-next {
                    &:hover {
                        background-color: $danger !important;
                    }
                }
            }

            &-s1 {
                display: flex;
                align-items: center;
                .swiper-button-prev,
                .swiper-button-next {
                    position: inherit;
                    top: auto;
                    margin: 8px;
                }
                .swiper-button-prev {
                    left: auto;
                }
                .swiper-button-next {
                    right: auto;
                }
            }
            &-s2 {
                .swiper-button-disabled {
                    display: none;
                }
                .swiper-button-next,
                .swiper-rtl .swiper-button-prev {
                    right: -8px;
                }
            }
            &.has-bg {
                .swiper-button-prev,
                .swiper-button-next {
                    background-color: $swiper-navigation-has-bg;
                    box-shadow: none;
                    color: $swiper-navigation-has-color;
                    &:hover {
                        background-color: $swiper-navigation-hover-bg;
                        color: $swiper-navigation-hover-color;
                    }
                }
            }
        }
    }

    &-pagination {
        &-s1 {
            bottom: 0 !important;
            .swiper-pagination-bullet {
                width: $swiper-pagination-s1-bullet-width;
                height: $swiper-pagination-s1-bullet-height;
                background-color: $swiper-pagination-s1-bullet-inactive-color;
                border-radius: $swiper-pagination-s1-bullet-border-radius;
                opacity: 1;
                &:hover,
                &-active {
                    background-color: $swiper-pagination-s1-bullet-active-color;
                }
            }
        }

        &-4 {
            .swiper-pagination-bullet {
                background-color: #fff;
                &-active {
                    background-color: $primary;
                }
            }
        }
    }

    // swiper pagination
    &.text-primary {
        .swiper-pagination-bullet-active {
            background-color: $primary;
        }
    }

    &.text-primary-alt {
        .swiper-pagination-bullet-active {
            background-color: $primary-alt;
        }
    }
    &.text-success,
    &.text-green {
        .swiper-pagination-bullet-active {
            background-color: $green;
        }
    }

    &.text-success-alt,
    &.text-green-alt {
        .swiper-pagination-bullet-active {
            background-color: $green-alt;
        }
    }

    &.text-purple {
        .swiper-pagination-bullet-active {
            background-color: $purple;
        }
    }

    &.text-danger {
        .swiper-pagination-bullet-active {
            background-color: $danger;
        }
    }
}

.is-theme {
    .swiper {
        &-pagination-bullet {
            background-color: $swiper-pagination-bullet-inactive-color-on-theme;
            opacity: 1;
            &-active {
                background-color: $swiper-pagination-bullet-active-color-on-theme;
            }
        }

        &-button-group {
            &.has-bg {
                .swiper-button-prev,
                .swiper-button-next {
                    color: $swiper-navigation-has-color-on-theme;
                    background-color: $swiper-navigation-has-bg-on-theme;
                    &:hover {
                        background-color: $swiper-navigation-has-bg-on-theme-hover-bg;
                        color: $swiper-navigation-has-bg-on-theme-hover-color;
                    }
                }
            }
        }
    }
}

.nk-swiper {
    &-s2 {
        .swiper {
            // components

            &-button-prev {
                left: 0;
            }

            &-button-next {
                right: 0;
            }

            &-slide {
                &:not(.swiper-slide-active) {
                    opacity: 0.7;
                    pointer-events: none;
                }
            }

            &-pagination {
                &-bullet {
                    opacity: 1;
                    background-color: #faf9ff;
                    &-active {
                        background-color: $primary;
                    }
                }
            }
        }

        &.overflow-visible {
            .swiper-button {
                &-prev {
                    transform: translateX(-50%);
                }
                &-next {
                    transform: translateX(50%);
                }
            }
        }

        // variants
        &.blog-slider {
            .swiper {
                &-pagination {
                    text-align: center;

                    @include media-breakpoint-up(xl) {
                        text-align: end;
                    }

                    &-bullet {
                        width: $swiper-pagination-s1-bullet-width;
                        height: $swiper-pagination-s1-bullet-height;
                        background-color: $swiper-pagination-s1-bullet-inactive-color;
                        border-radius: $swiper-pagination-s1-bullet-border-radius;
                        opacity: 1;
                        &:hover,
                        &-active {
                            background-color: $swiper-pagination-s1-bullet-active-color;
                        }
                    }
                }
            }
        }
    }

    &-s3 {
        // swiper component
        .swiper {
            &-button-prev,
            &-button-next {
                &.swiper-button-disabled {
                    opacity: 0;
                }
            }
        }
    }

    &-s4 {
        .swiper {
            // components
            &-button {
                &-prev,
                &-next {
                    transform: translateY(-50%);
                }

                &-prev {
                    left: 12px;
                }

                &-next {
                    right: 12px;
                }
            }

            &-slide {
                transition: all 0.2s linear;
            }

            &-pagination {
                &-bullet {
                    opacity: 1;
                    background-color: #e5e7eb;
                    &-active {
                        background-color: $primary;
                    }
                }
            }
        }

        &.overflow-visible {
            .swiper-button {
                &-prev {
                    transform: translateX(-50%);
                }
                &-next {
                    transform: translateX(50%);
                }
            }
        }
    }
}

// responsive devices
@include media-breakpoint-up(lg) {
    .nk-swiper {
        &-s1 {
            .swiper-slide {
                transition: 0.5s;
                transform: scale(0.6);
                &-active {
                    transform: scale(1);
                    transform-origin: center;
                }
                &-prev {
                    transform-origin: right;
                }
                &-next {
                    transform-origin: left;
                }
            }
        }

        &-s4 {
            .swiper {
                &-slide {
                    transition: all 0.2s linear;
                    &-active {
                        padding-left: 100px;
                    }
                    &-next {
                        padding-right: 100px;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(xxl) {
    .swiper-button {
        &-group {
            &-s2 {
                .swiper-button-next,
                .swiper-rtl .swiper-button-prev {
                    right: -60px;
                }
                .swiper-button-prev,
                .swiper-rtl .swiper-button-next {
                    left: -60px;
                }
            }
        }
    }
}
