.lead {
    line-height: $lead-line-height;
    letter-spacing: -$lead-letter-spacing;
}

@include media-breakpoint-down(xxl) {
    .display-6 {
        font-size: 2.625rem;
    }
}

@include media-breakpoint-down(md) {
    .display-6 {
        font-size: 2.375rem;
    }
}

@include media-breakpoint-down(sm) {
    .display-6 {
        font-size: 1.75rem;
    }
    .h1,
    h1 {
        font-size: 1.625rem;
    }
    .h2,
    h2 {
        font-size: 1.5rem;
    }

    .h5,
    h5 {
        font-size: 1.125rem;
    }

    .lead,
    p {
        font-size: 1rem;
    }

    .nk-block-title {
        font-size: 1.125rem;
    }

    .card-title {
        font-size: 1.1875rem;
    }
}
