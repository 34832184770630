$testimonial-class: "nk-testimonial";

.#{$testimonial-class} {
    &-card {
        height: 100%;
        display: flex;
        padding: 1.5rem;
        align-items: center;
        border-radius: 1rem;
        flex-direction: column;
        border: $testimonial-card-border;

        // variants
        &-s1 {
            padding: 0px;
            @include media-breakpoint-up(md) {
                padding: 2rem;
            }
            .#{$testimonial-class} {
                &-cover {
                    border-radius: 0.7rem 0.7rem 0 0;
                    @include media-breakpoint-up(xl) {
                        padding-top: 0px;
                        border-radius: 0.7rem;
                    }

                    img {
                        @include media-breakpoint-up(xl) {
                            margin-top: -40px;
                        }
                    }
                }

                &-content {
                    z-index: 1;
                    position: relative;
                    .#{$testimonial-class} {
                        &-icon {
                            opacity: 0.6;
                            top: -50px;
                            left: -12px;
                            z-index: -1;
                            position: absolute;
                            @include media-breakpoint-down(xl) {
                                display: none;
                            }
                            @include media-breakpoint-up(xl) {
                                opacity: 1;
                                z-index: 1;
                                position: static;
                            }
                            .icon {
                                font-size: 5.625rem;
                                background: $testimonial-card-s1-quote-icon-color;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }
                        }
                    }

                    h3 {
                        @media (max-width: 1200px) {
                            font-size: 28px;
                        }

                        @media (max-width: 767px) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }

        &-s2 {
            position: relative;
            box-shadow: 0 0px 6px rgba($base-color, 0.1);

            .#{$testimonial-class}-content {
                position: relative;
                z-index: 5;
            }
            .#{$testimonial-class}-media {
                margin-top: -80px;
            }

            &::after {
                z-index: 1;
                opacity: 0.1;
                right: -40px;
                bottom: 70px;
                color: $danger;
                line-height: 0;
                font-size: 16rem;
                content: "\ecbc";
                position: absolute;
                font-family: $nio-font-family;
            }
        }
        &-s3 {
            border-radius: $border-radius;
            box-shadow:
                -4px -4px 10px rgba(192, 192, 192, 0.12),
                4px 4px 10px rgba(192, 192, 192, 0.16);
            p {
                color: $base-color;
            }

            .#{$testimonial-class} {
                &-icon {
                    .icon {
                        font-size: 84px;
                        background: $testimonial-card-icon-background-color;
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
        &-s4 {
            background-color: transparent;
            border-radius: 0;
            box-shadow: none;
            padding: 0 !important;
            text-align: center;
            .#{$testimonial-class}-content {
                padding-left: 0 !important;
            }
        }

        &-s5 {
            padding: 24px;
            border-top: 8px solid $primary;
            padding: 0px 0px 1.5rem 1.5rem;
            box-sizing:
                4px 4px 36px 0px #c0c0c029,
                -4px -4px 36px 0px #c0c0c01f;

            @include media-breakpoint-up(md) {
                padding: 3.75rem;
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .#{$testimonial-class} {
        .card {
            &-s1 {
                .#{$testimonial-class} {
                    &-img {
                        height: 23.75rem;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .#{$testimonial-class} {
        &-card-s2 {
            .#{$testimonial-class}-media {
                margin-top: -108px;
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .#{$testimonial-class} {
        &-card {
            flex-direction: row;
            .#{$testimonial-class}-img {
                margin-bottom: 0;
            }
            &-s3 {
                padding: 2rem;
                .#{$testimonial-class}-content {
                    padding-left: 0;
                }
            }

            &-s2 {
                padding: 3rem;
                .#{$testimonial-class}-content {
                    padding-left: 0;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .#{$testimonial-class} {
        &-content {
            p.h3 {
                font-size: 18px;
                line-height: 24px;
            }
        }

        &-card {
            &-s1 {
                .#{$testimonial-class} {
                    &-wrap {
                        padding: 24px;
                        .nk-testimonial-card {
                            box-shadow:
                                -4px -4px 24px rgb(192 192 192 / 12%),
                                4px 4px 24px rgb(192 192 192 / 16%);
                        }
                    }
                }
            }
        }
    }
}
