.#{$pricing-class} {
    &-s1 {
        padding: 0px;
        position: relative;
        border: $pricing-s1-border;
        background-color: $pricing-s1-bg;
        border-radius: $pricing-s1-border-radius;
        flex-grow: 1;
        .#{$pricing-class} {
            &-body {
                .nk-list-link {
                    .icon {
                        top: 0;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-size: $pricing-s1-icon-font-size;
                        width: $pricing-s1-icon-width;
                        height: $pricing-s1-icon-height;
                        border-radius: $pricing-s1-icon-border-radius;
                        color: $pricing-s1-icon-color;
                    }
                }
            }
            &-badge-wrap {
                padding-bottom: 12px;
                @include media-breakpoint-up(sm) {
                    top: 40px;
                    right: 32px;
                    position: absolute;
                    text-align: right;
                    padding-bottom: 0px;
                }

                .badge {
                    color: $darker;
                }
            }
        }
        &.active {
            border-radius: 0px;
            position: relative;
            color: $pricing-active-color;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            .h1,
            .h2,
            .h3,
            .h4,
            .h5,
            .h6,
            p {
                color: $pricing-active-color;
            }
            p,
            li,
            .amount-text {
                font-weight: 400;
            }

            &::after {
                content: "";
                top: 0;
                z-index: 0;
                right: -50%;
                width: 100%;
                height: 100%;
                user-select: none;
                position: absolute;
                pointer-events: none;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                background-image: url("#{$coreRoot}mask/mask-line-1.png");
            }

            .#{$pricing-class} {
                &-body {
                    .nk-list-link {
                        .icon {
                            background-color: $pricing-active-icon-bg;
                            color: $pricing-active-color;
                        }
                        li {
                            &.active {
                                color: $pricing-active-color;
                                .icon {
                                    color: $pricing-active-color;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &-s2 {
        border-radius: $pricing-s2-border-radius;
        .nk-pricing-head .text {
            font-size: $pricing-s2-text-font-size;
        }
        sub {
            bottom: 3px;
            font-size: $pricing-s2-sub-font-size;
        }
        .btn-light {
            background-color: $gray-500;
        }
    }
    &-s3 {
        display: flex;
        flex-direction: column;
        background-color: $pricing-s3-bg;
        border-radius: $pricing-s3-border-radius;
        &.active {
            background-color: $pricing-active-bg;
            color: $pricing-active-color;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            .h1,
            .h2,
            .h3,
            .h4,
            .h5,
            .h6,
            p {
                color: $pricing-active-color;
            }
            p,
            li,
            .amount-text {
                font-weight: 400;
            }
            .#{$pricing-class} {
                &-body {
                    .nk-list-link {
                        .icon {
                            color: $pricing-active-color;
                        }
                        li {
                            &.active {
                                color: $pricing-active-color;
                                .icon {
                                    color: $pricing-active-color;
                                }
                            }
                        }
                    }
                }
            }
            .btn-outline-primary {
                background-color: $pricing-active-btn-bg;
                color: $pricing-active-btn-color;
                border-color: $pricing-active-btn-border-color;
            }
        }
        .#{$pricing-class} {
            &-body {
                flex-grow: 1;
                display: flex;
                flex-direction: column;
            }
        }
    }
    &-toggle {
        .text {
            margin-bottom: 0;
        }
        &-s1,
        &-s2 {
            display: inline-flex;
            align-items: center;
            position: relative;
            padding: $pricing-toggle-s1-padding;
            border-radius: $pricing-toggle-s1-border-radius;
            input[type="radio"] {
                position: absolute;
                opacity: 0;
            }
            input[type="radio"]:checked + label + .switch-highlight,
            input[type="radio"]:checked + label:nth-of-type(n) + .switch-highlight {
                transform: $pricing-toggle-s1-switch-highlight-translate;
            }
            label {
                position: relative;
                z-index: 1;
                display: inline-block;
                width: $pricing-toggle-s1-label-width;
                line-height: $pricing-toggle-s1-label-line-height;
                cursor: pointer;
                font-size: $pricing-toggle-s1-label-font-size;
                color: $pricing-toggle-s1-label-color;
            }
        }
        &-s1 {
            background-color: $pricing-toggle-s1-bg;
        }
        &-s2 {
            background-color: $pricing-toggle-s2-bg;
            border: $pricing-toggle-s2-border;
            label {
                color: $pricing-toggle-s2-label-color;
            }
            input[type="radio"]:checked + label {
                color: $pricing-toggle-s2-label-active-color;
            }
        }
        .switch-highlight {
            position: absolute;
            background-color: currentColor;
            top: $pricing-toggle-s1-switch-highlight-top;
            left: $pricing-toggle-s1-switch-highlight-left;
            height: $pricing-toggle-s1-switch-highlight-height;
            width: $pricing-toggle-s1-switch-highlight-width;
            border-radius: $pricing-toggle-s1-switch-highlight-border-radius;
            transition: transform 0.5s;
        }

        &-s3 {
            .form-check {
                input:not(:checked) + .two {
                    opacity: 0.6;
                }
            }
        }
    }
    &-head {
        .icon:not(.badge .icon) {
            font-size: $pricing-head-icon-size;
            color: $pricing-head-icon-color;
            margin-right: $pricing-head-icon-gap-x;
        }
        .text {
            font-size: $pricing-head-text-font-size;
            &-2 {
                font-size: $pricing-head-text-font-size-2;
            }
        }
    }
    &-title-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &-body {
        .amount {
            &-text {
                font-size: 1.125rem;
            }
        }
        .nk-list-link {
            .icon {
                font-size: 1.25rem;
                position: relative;
                top: 2px;
            }
        }
    }
    .nk-year-amount {
        display: none;
    }
    &.is-active {
        .nk-year-amount {
            display: block;
        }
        .nk-month-amount {
            display: none;
        }
    }
    &-wrap {
        &-s1 {
            padding: 1.875rem;
            background-color: $pricing-wrap-s1-bg;
            border-radius: $pricing-wrap-s1-border-radius;
            border: $pricing-wrap-s1-border;
        }
    }
    &-group {
        display: flex;
        flex-wrap: wrap;
        background-color: $pricing-group-bg;
        border-radius: $pricing-group-border-radius;
    }
}
// compare price
.nk-compare {
    border: $compare-border;
    border-radius: $compare-border-radius;
    background-color: $compare-bg;
    .table {
        margin-bottom: 0;
    }
    &-col {
        padding: $compare-col-padding-y 1rem !important;
        &.empty-col {
            border-bottom-width: 0;
        }
        &.lg {
            width: $compare-col-width-lg;
        }
        &.active {
            background-color: $compare-col-active-bg;
        }
        &.center {
            text-align: center;
        }
        .lead {
            white-space: nowrap;
            font-size: $compare-col-lead-font-size;
            color: $compare-col-lead-color;
        }
    }
    thead {
        .nk-compare-col {
            padding-top: $compare-head-col-padding-y !important;
            padding-bottom: 0 !important;
        }
    }
}

@include media-breakpoint-up(lg) {
    .nk-compare {
        padding-left: $compare-padding-x;
        padding-right: $compare-padding-x;
    }
    .nk-pricing-group {
        .#{$pricing-class} {
            width: 50%;
        }
    }

    .#{$pricing-class} {
        &-wrap {
            &-s1 {
                padding: $pricing-wrap-s1-padding;
            }
        }
    }
}
@include media-breakpoint-up(xxl) {
    .nk-pricing-group {
        .#{$pricing-class} {
            width: 25%;
        }
    }
    .#{$pricing-class}-s1 {
        .#{$pricing-class} {
            &-badge-wrap {
                width: 100%;
                top: -62px;
                left: 0;
                right: 0;
                border-top-left-radius: $pricing-active-badge-wrap-border-radius;
                border-top-right-radius: $pricing-active-badge-wrap-border-radius;
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .nk-compare-col {
        padding: 12px 16px !important;
    }
}
