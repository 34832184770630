.call-to {
  overflow: hidden;
  position: relative;
  background-color: $darker;

  &-action {
    gap: 0.75rem;
    text-align: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;

    a {
      &:nth-child(2) {
        gap: 0.875rem;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: $white;
          font-size: 0.75rem;
          line-height: 1.25rem;
        }
      }
    }
  }

  &-info {
    p {
      margin: 0;
      opacity: 0.6;
    }
  }
}
