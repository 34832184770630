// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode style

@import "../../../../../node_modules/bootstrap/scss/variables";
@import "../../../../../node_modules/bootstrap/scss/variables-dark";

// 4. Include any default map overrides here

// BS ( bootstrap ) conainer max-widths
$container-max-widths: override-map-value($container-max-widths, xxl, 1350px);

// BS ( bootstrap ) overrides colors map variables

$nk-colors: (
  "info": $info,
  "info-alt": $info-alt,
  "gray": $gray-500,
  "gray-dark": $gray-800,
  "purple-alt": $purple-alt,
  "indigo-alt": $indigo-alt
);

$colors: map-merge($colors, $nk-colors);

// BS ( bootstrap ) overrides theme-colors map variables

$nk-theme-colors: (
  "red": $red,
  "dark": $dark,
  "cyan": $cyan,
  "white": $white,
  "purple": $purple,
  "indigo": $indigo,
  "darker": $darker,
  "yellow": $yellow,
  "info-alt": $info-alt,
  "success-alt": $green-alt,
  "purple-alt": $purple-alt,
  "indigo-alt": $indigo-alt,
  "primary-alt": $primary-alt,
  "dark-alt": $dark-alt,
  "blue": $blue,
  "gray": $gray,
  "gray-50": $gray-50,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900
);

// BS ( bootstrap ) shades-variables  overrides/include

// $grays: add-to-map($grays, "gray-50", $gray-50, start);
$reds: add-to-map($reds, "red-50", $red-50, start);
$blues: add-to-map($blues, "blue-50", $blue-50, start);
$cyans: add-to-map($cyans, "cyan-50", $cyan-50, start);
$greens: add-to-map($greens, "green-50", $green-50, start);
$indigos: add-to-map($indigos, "indigo-50", $indigo-50, start);
$purples: add-to-map($purples, "purple-50", $purple-50, start);
$yellows: add-to-map($yellows, "yellow-50", $yellow-50, start);
$oranges: add-to-map($oranges, "orange-50", $orange-50, start);

// BS ( bootstrap )  overrides theme-color-map
$theme-colors: map-merge-multiple(
  $theme-colors,
  $nk-theme-colors,
  $grays,
  $blues,
  $reds,
  $greens,
  $indigos,
  $yellows,
  $cyans,
  $purples,
  $oranges
);
