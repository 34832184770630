%overlay {
  z-index: 1;
  content: "";
  position: absolute;
}

.overlay {
  &-1 {
    &::before {
      width: 100%;
      height: 178px;
      @extend %overlay;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    }
  }

  &-2 {
    &::before {
      @media (min-width: 992px) and (max-width: 1200px) {
        top: 0;
        left: 0;
        content: "";
        z-index: -1;
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  // position
  &-bottom {
    &::before {
      left: 0;
      bottom: 0;
    }
  }
}
