.bg-primary-gradient {
    background: $bg-primary-gradient;
}

.bg-primary-gradient-soft {
    background: $bg-primary-gradient-soft;
}
.bg-primary-dark {
    background-color: $primary-dark;
}
