.nk-search-group {
  gap: 45px;
  display: flex;
  border-radius: 8px;
  padding: 12px 24px;
  position: relative;
  padding-right: 12px;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  border: $border-width solid $hr-border-color;

  // elements
  .nk-search-element {
    gap: 14px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    & > div {
      position: relative;

      &::after {
        @include media-breakpoint-up(md) {
          content: "";
          top: 50%;
          width: 1px;
          right: -7px;
          height: 50%;
          position: absolute;
          transform: translateY(-50%);
          background-color: rgba(10, 20, 37, 0.4);
        }
      }

      &:nth-last-child(1) {
        &::after {
          @include media-breakpoint-up(md) {
            display: none;
          }
        }
      }
    }
  }

  .nk-search-action {
    flex-shrink: 0;
  }

  .nk-search-field {
    gap: 8px;
    display: flex;
    align-items: center;

    .icon {
      opacity: 0.4;
    }

    input {
      border: 0px;
      &:focus,
      &:active {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }

  .nk-dropdown {
    text-align: left;
    .icon {
      opacity: 0.4;
    }

    span {
      opacity: 0.8;
    }

    &-select {
      width: 110%;

      &-option {
        padding: 8px;
      }
    }
  }
}
