$footer: "nk-footer";

.#{$footer} {
  background-color: $footer-bg-color;

  // Theme Base
  &.is-theme {
    .#{$footer} {
      &-content {
        border-bottom-color: #2b292c;
      }

      &-brand-info {
        p {
          color: $gray-600;
        }
      }

      &-info {
        .title {
          color: #fff;
        }

        ul {
          li {
            a {
              color: $gray-600;
            }
          }
        }
      }

      &-copyright {
        li {
          &::after {
            background-color: #fff;
          }

          a {
            color: #fff;
          }
        }
      }
    }
  }

  &-top {
    .#{$footer} {
      &-content {
        border-bottom: $footer-content-border;
        padding: calc($footer-top-content-spacing / 1.2) 0px;

        @include media-breakpoint-up(md) {
          padding: $footer-top-content-spacing 0px;
        }
      }
    }
  }

  &-bottom {
    .#{$footer} {
      &-content {
        padding: calc($footer-bottom-content-spacing / 2) 0px;

        @include media-breakpoint-up(md) {
          padding: $footer-bottom-content-spacing 0px;
        }
      }
    }
  }

  &-menu {
    gap: 16px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(md) {
      flex-wrap: nowrap;
      gap: 48px;
    }

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    @include media-breakpoint-up(xl) {
      gap: 90px;
    }

    li {
      a {
        color: $darker;
        font-weight: 600;
        font-size: 14px;
        line-height: 1.14;
        text-align: right;
        text-transform: uppercase;
        transition: all 0.3s linear;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  &-text {
    transform: scale(1);
    transition: all 0.2s linear;

    &:hover {
      transform: scale(0.9);
    }
  }

  &-copyright-text {
    color: $footer-text-color;
    font-size: $footer-text-size;
    line-height: $footer-text-line-height;
    font-weight: $footer-text-font-weight;
    text-transform: $footer-text-transform;
  }

  &-brand {
    &-info {
      .#{$footer} {
        &-logo {
          margin-bottom: 16px;
        }
      }
      p {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }

  &-social {
    display: flex;
    align-items: center;
    gap: $footer-social-gap;

    li {
      a {
        width: 40px;
        height: 40px;
        display: flex;
        transform: scale(1);
        align-items: center;
        justify-content: center;
        transition: all 0.1s linear;

        &:hover {
          transform: scale(0.9);
        }
      }
    }
  }

  &-info {
    @include media-breakpoint-down(sm) {
      margin-bottom: 24px;
    }

    .title {
      margin-bottom: 24px;
      color: $footer-info-title-color;
      font-size: $footer-info-title-font-size;
      line-height: $footer-info-title-line-height;
      font-weight: $footer-info-title-font-weight;
      text-transform: $footer-info-title-transform;

      @include media-breakpoint-up(sm) {
        margin-bottom: $footer-info-title-bottom-space;
      }
    }

    ul {
      li {
        a {
          transition: all 0.3s linear;
          color: $footer-info-text-color;
          font-size: $footer-info-text-size;
          line-height: $footer-info-text-line-height;
          text-transform: $footer-info-text-transform;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }

  &-copyright {
    gap: 6px;
    display: flex;
    align-items: center;

    @include media-breakpoint-up(sm) {
      gap: 16px;
    }

    li {
      position: relative;
      &::after {
        content: "";
        top: 50%;
        width: 1px;
        right: -4px;
        position: absolute;
        height: calc(100% - 12px);
        transform: translateY(-50%);
        background-color: currentColor;

        @include media-breakpoint-up(sm) {
          right: -8px;
        }
      }

      &:nth-last-child(1) {
        &::after {
          display: none;
        }
      }

      a {
        white-space: nowrap;
      }
    }
  }
}

.nk-footer {
  &-landing {
    &-copyright {
      text-align: center;
      background-color: #122544;

      span {
        color: #fff;
        font-size: 0.875rem;
        line-height: 1.375rem;
      }
    }
  }

  &-bottom {
    .nk-list-link {
      li {
        position: relative;

        &::after {
          content: "";
          width: 1px;
          top: 50%;
          right: 0px;
          height: 80%;
          position: absolute;
          background-color: $dark;
          transform: translateY(-50%);
        }

        &:nth-last-child(1) {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
