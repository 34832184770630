$header-class: "nk-header";
$navbar-overlay-transition: 400ms ease;

.#{$header-class} {
    &-main {
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        position: fixed;
        padding: 0.8438rem 0px;
        transition: padding 0.2s linear;

        &.has-separate {
            border-bottom: 1px solid $header-main-border;
        }

        &.has-fixed {
            padding: 6px 0px;
        }
    }

    &-wrap {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &-logo {
        display: inline-flex;
    }

    &-action {
        padding-top: 0.375rem;
        padding-bottom: 0.375rem;
    }
}

.nk {
    &-navbar-toggle {
        .btn {
            padding: 0.75rem !important;
            .icon {
                font-size: 1.375rem;
            }
        }
    }
}

.navbar {
    &-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $navbar-overlay;
        z-index: 1025;
        animation: overlay-fade-out $navbar-overlay-transition 1;
    }
    &-shown {
        overflow: hidden;
        .navbar-overlay {
            animation: overlay-fade-in $navbar-overlay-transition 1;
        }
    }
}

@keyframes overlay-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes overlay-fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
