/*!
 * Template Name: NioLand
 * Author: Softnio
 * Author URI: http://themeforest.net/user/softnio
 * Version: 1.0.0
 * Updated: 10.07.2023
**/

/** IMPORTANT NOTE: DO NOT Edit this file. Best to write own code in css/theme.css file. **/

/* 
----------------------
## Table Contents ##
---------------------- 
**/

////////////////////////// DO NOT REMOVE BELOW ///////////////////////

$extendRoot: "../../../../images/";
$coreRoot: "../../../images/";
$fontRoot: "../../../fonts/";

@import "variables";
@import "vendors/nioicon/variables";

// Override Bootstarp variables
@import "extend/bootstrap/variables";

// Included Fonts
@import "core/fonts";

// Included Bootstarp
@import "extend/bootstrap/bootstrap";
@import "extend/bootstrap/extend";

// Include Other Vendors
@import "vendors/bundle";

// Include Core Style
@import "core/style";

// Write Your Own Style
@import "theme.scss";
