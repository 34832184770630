@mixin text-bg-soft-variant($color, $background: mix($color, $white, 12%), $border: transparent) {
    color: #{$color} !important;
    background-color: #{$background} !important;
    border-color: #{$border};
}

@mixin text-bg-solid-variant($color, $background: mix($color, $white, 100%), $border: transparent) {
    color: #fff !important;
    background-color: #{$background} !important;
    border-color: #{$border};
}

@mixin bg-soft-variant($color, $background: mix($color, $white, 24%)) {
    background-color: #{$background};
}

@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include text-bg-solid-variant($value);
    }

    .btn-#{$color}-soft {
        @include text-bg-soft-variant($value);
    }

    .bg-#{$color}-soft {
        @include bg-soft-variant($value);
    }

    .text-bg-#{$color}-soft {
        @include text-bg-soft-variant($value);
    }

    // .text-bg-#{$color} {
    //     @include text-bg-solid-variant($value);
    // }
}

@each $color, $value in $theme-gradient-colors {
    .bg-gradient-#{$color} {
        background: $value;
    }
}
