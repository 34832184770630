.nk-btn {
    &-group {
        gap: 1rem;
        display: flex;
        &.sm {
            gap: -0.5rem;
            > * {
                gap: 0.5rem;
            }
        }

        .btn {
            @include media-breakpoint-down(sm) {
                padding: 8px 16px;
                font-size: 14px !important;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .nk-btn {
        > * {
            gap: 0.75rem;
        }
    }
}
