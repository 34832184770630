.gap {
    margin-top: calc(-1 * var(--#{$prefix}gutter-y));
    margin-right: calc(-0.5 * var(--#{$prefix}gutter-x));
    margin-left: calc(-0.5 * var(--#{$prefix}gutter-x));
    &[class*="gy-"],
    &[class*="gx-"] {
        margin-left: 0;
        margin-right: 0;
        > * {
            padding-left: 0;
            padding-right: 0;
        }
    }
    > * {
        padding-right: calc(var(--#{$prefix}gutter-x) * 0.5);
        padding-left: calc(var(--#{$prefix}gutter-x) * 0.5);
        margin-top: var(--#{$prefix}gutter-y);
    }
}
